<template>
  <div>
    <HeaderNavHomepage />
    <!-- <PricingHeaderCC /> -->
    <!-- <ForContentCreators /> -->
    <!-- <ExpandDetailsCC /> -->

    <section class="bg-black">
      <div class="main-title">
        <h1>Enable millions in revenue</h1>

        <p>Custom website, digital payments & full suite ticketing solution</p>

        <p class="opacity-50">
          Start for free in 1 day. No registration fees or subscriptions. No
          lock-ins.
        </p>

        <section class="percentage-box">
          <section class="percentage-box-main">
            <p class="percentage-box-title">Simple Revenue-share model</p>

            <div class="percent-table">
              <div class="percent-table-row">
                <div
                  class="percent-table-cell percent-table-revenue-col percent-bar-label"
                >
                  Your Revenues
                </div>
                <div class="percent-table-cell">
                  <img src="/img/main-icon.svg" height="24" width="24" />
                </div>
              </div>
              <div class="percent-table-row">
                <div class="percent-table-cell percent-table-revenue-col">
                  <div class="extra-border"></div>
                  <div class="percent-bar percent-bar-fill"><div>95%</div></div>
                </div>
                <div class="percent-table-cell">
                  <div class="percent-bar percent-bar-empty"><div>5%</div></div>
                </div>
              </div>
            </div>

            <p class="note">
              + Payment Processing Fees (1.5%–3% for all local payment methods)
            </p>
            <p class="note text-italic">
              Includes ALL HelixPay features + GCash + Credit/Debit Card and
              cash payment methods active from Day 1
            </p>
          </section>

          <section class="percentage-box-footer">
            <div>
              <p>
                Includes world-class event ticketing technology for just
                ₱20/ticket
              </p>
              <small class="text-pink text-italic">*charged to customer</small>
            </div>
          </section>
        </section>
      </div>
    </section>

    <MainFooter />

    <MessengerFAB />
  </div>
</template>

<script>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import MessengerFAB from '@/components/MessengerFAB.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  components: {
    HeaderNavHomepage,
    MainFooter,
    MessengerFAB,
  },

  setup() {
    useHead(
      buildPageMeta({
        title: 'Pricing | Ecommerce Technology for Creators & Communities',
        description:
          'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
        url: 'https://www.helixpay.ph/pricing',
        image: 'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
      })
    );
  },
};
</script>

<style scoped>
.bg-black {
  background-color: black;
}

.main-title {
  text-align: center;
  max-width: 71rem;
  padding: 8rem 2rem;
  margin: auto;
}

.main-title h1 {
  color: #ff5f8f;
  font-size: 3rem;
  font-weight: 700;
}

.opacity-50 {
  opacity: 0.5;
}

span {
  color: #ff5f8f;
}

.text-pink {
  color: #ff5f8f;
}

.main-title > p {
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
}

.percentage-box {
  background: linear-gradient(to right, #220533, #480251);
  max-width: 50rem;
  margin: auto;
  margin-top: 4rem;
  border-radius: 10px;
}

@media only screen and (max-width: 900px) {
  .main-title > h1 {
    font-size: 2rem;
  }

  .main-title > h4 {
    font-size: 1rem;
  }

  .main-title > p {
    font-size: 1rem;
  }
}

.percent-table {
  display: table;
  width: 100%;
  margin-bottom: 1.75rem;
}

.percent-table-row {
  display: table-row;
}

.percent-table-revenue-col {
  width: 95%;
  position: relative;
  font-weight: 700;
}

.percent-table-cell {
  display: table-cell;
}

.percent-table-cell img {
  height: 24px;
  width: 24px;
  margin-bottom: 0.5rem;
}

.note {
  font-size: 0.85rem;
  opacity: 0.75;
  margin-top: 0.5rem;
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: 500;
}

.percentage-box-main {
  padding: 1.5rem;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.percentage-box-footer {
  color: white;
  padding: 1.5rem 1.5rem 1.25rem 1.5rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
}

.percentage-box-footer p {
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin: 0;
}

.percentage-box-footer small {
  display: block;
  letter-spacing: 0.5px;
  font-weight: 700;
  line-height: 1.35;
  text-align: center;
  margin-top: 0.5rem;
}

.text-italic {
  font-style: italic;
}

.percent-bar {
  padding: 8px;
  font-weight: 700;
  line-height: 1;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.percent-bar-fill {
  border-radius: 10px;
  background: linear-gradient(to right, #25a4e1, #4cd4ff);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.extra-border {
  border-width: 0;
  border-top-width: thin;
  border-bottom-width: thin;
  border-style: solid;
  border-color: #777;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.percent-bar-empty {
  border-width: thin;
  border-left-width: 0;
  border-style: solid;
  border-color: #777;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.percent-bar-label {
  font-weight: 700;
  line-height: 2rem;
}

.percentage-box-title {
  font-weight: 700;
  opacity: 0.5;
  text-align: center;
  font-size: 0.95rem;
  margin-top: -0.25rem;
}

@media (min-width: 900px) {
  .percentage-box-footer p,
  .percent-bar-label,
  .percent-bar {
    font-size: 1.25rem;
  }

  .percentage-box-footer small {
    text-align: right;
  }

  .percentage-box-main {
    padding: 2rem;
  }

  .percentage-box-footer {
    padding: 2rem 2rem 1.5rem 2rem;
  }

  .percentage-box-title {
    text-align: left;
  }
}
</style>
